
import {computed, defineComponent, ref} from "vue";
import {PinaFilterObjects} from "@/core/composite/composite";
import {useUserStore} from "@/store/user.store";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Entities from "@/components/base/common/Entities.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";
import TeamService from "@/core/services/GroupService";
import Swal from "sweetalert2";
import {useStore} from "vuex";
import UserStatus from "@/views/user/UserStatus.vue";

export default defineComponent({
  name: "Members",
  components: {UserStatus, UserListSelect, BaseForm, BaseModal, DateTimeFormat, KtDatatable, Entities, QuickAction},
  props: {
    teamId: {type: String, required: true}
  },
  setup(props) {
    const vuexStore = useStore();
    const submitting = ref(false);
    const model = ref({userIds: []})
    const user = computed(()=>vuexStore.getters.currentUser);
    const filterObject = ref({teamId: props.teamId});
    const store = useUserStore();
    const page = computed(() => store.page);
    const tableHeader = ref([
      {
        name: "Name",
        key: "fullName",
        sortable: true,
      },
      {
        name: "Last Login",
        key: "lastLogin",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },

      {
        name: "",
        key: "actions",
        sortable: true,
      },
    ]);
    return {
      tableHeader,
      model,
      page,
      user,
      submitting,
      ...PinaFilterObjects(store, filterObject.value, []),
    }
  },
  methods: {
    onAdd() {
      this.model.userIds = [];
      const modal = this.$refs.addMemberRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onSave() {
      this.submitting = true
      TeamService.addUser(this.teamId, this.model).then(() => {
        this.paginationDataLoad()
        const modal = this.$refs.addMemberRef as typeof BaseModal;
        modal.hideBaseModal();
      }).finally(() => {
        this.submitting = false;
      })
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await TeamService.remove(this.teamId, id).then(() => {
            this.paginationDataLoad();
          })
        }
      })
    }
  }
})
